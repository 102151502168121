import { useCallback } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import Geocode from 'react-geocode';
import * as Sentry from '@sentry/react';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '');

type Props = {
  locationName: string;
  address: string;
  city: string;
  state: string;
  setGoogleFooter: (value: string, success: boolean) => void;
};

export const useAutofillGoogleFooter = ({ locationName, address, city, state, setGoogleFooter }: Props) => {
  const locationSearch = `${address} ${city} ${state}`;

  const autofillGoogleFooter = useCallback(async () => {
    try {
      const { results } = await Geocode.fromAddress(locationSearch);
      const latAndLng = parseLatAndLng(results);

      if (!latAndLng) {
        throw Error(`Could not find latitude and longitude for location: ${locationSearch}`);
      }

      const nearbyCities = await fetchNearbyCities(latAndLng);
      const cities = [nearbyCities.slice(0, -1).join(', '), nearbyCities.slice(-1)[0]].join(
        nearbyCities.length < 2 ? '' : ' and '
      );
      const message = `Visit ${locationName} for low prices & exceptional service! We proudly serve ${cities}.`;
      setGoogleFooter(message, true);
    } catch (error: any) {
      setGoogleFooter(error.message, false);
      Sentry.captureException(error);
    }
  }, [locationSearch, locationName, setGoogleFooter]);

  return autofillGoogleFooter;
};

const parseLatAndLng = (results: any[]) => {
  if (results.length === 0) {
    return null;
  }

  const result = results[0];
  let {
    geometry: {
      location: { lat, lng },
    },
  } = result;

  lng = lng > 0 ? `+${lng}` : lng;
  return { lat, lng };
};

const fetchNearbyCities = async ({ lat, lng }: { lat: string; lng: string }) => {
  var locationId = `${lat}${lng}`;
  var params = {
    radius: 50,
    distanceUnit: 'MI',
    limit: 10,
    offset: 0,
    sort: '-population',
    types: ['CITY'],
  };

  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `https://wft-geo-db.p.rapidapi.com/v1/geo/locations/${locationId}/nearbyCities`,
    params,
    headers: {
      'X-RapidAPI-Key': process.env.REACT_APP_GEO_DB_API_KEY,
      'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com',
    },
  };

  const {
    data: { data },
  } = await axios.request(options);
  return data.map(({ name, regionCode }: { name: string; regionCode: string }) => `${name}, ${regionCode}`);
};
