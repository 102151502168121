import React from 'react';
import { Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SearchInput } from '../../../components/ui/TextInput/SearchInput.styled';
import { createSortProps } from '../../../utils/table.utils';
import { LocationHealthSummary } from '../../../graphql/query/locationHealthSummaries';

const { Column } = Table;
const PageContent = styled.div`
  padding: 10px 10px;
  width: 100%;
  align-items: flex-start;
`;
const PageHeader = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
interface HealthScoreColumnParams {
  healthSummaries: LocationHealthSummary[];
  handleSearch: (value: string) => void;
}

export const HealthScoreColumn: React.FC<HealthScoreColumnParams> = ({ healthSummaries, handleSearch }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handleSearch(value);
  };

  return (
    <>
      <PageHeader>
        <SearchInput placeholder="Search by location or organization" onChange={handleChange} removeBorder />
      </PageHeader>
      <PageContent>
        <Table pagination={{ pageSize: 50 }} className="wide-table" dataSource={healthSummaries} rowKey="id">
          <Column<LocationHealthSummary>
            title="Organization"
            width="160px"
            dataIndex="organizationName"
            key="organizationName"
            render={(organizationName) => organizationName}
            {...createSortProps<LocationHealthSummary>({
              type: 'string',
              field: 'organizationName',
              multiple: 1,
            })}
          />
          <Column<LocationHealthSummary>
            title="Location"
            width="240px"
            dataIndex="locationName"
            key="locationName"
            //@ts-ignore
            render={(locationName, row) => <Link to={`/app/health/${row.locationId}`}>{locationName}</Link>}
          />
          <Column<LocationHealthSummary>
            title="Health"
            width="240px"
            dataIndex="healthLevel"
            key="healthLevel"
            render={(healthLevel) => {
              const color = healthLevel === 'Green' ? 'green' : healthLevel === 'Yellow' ? 'yellow' : 'red';
              return <Tag color={color}>{healthLevel}</Tag>;
            }}
          />
          <Column<LocationHealthSummary>
            title="Assigned To"
            width="240px"
            dataIndex="assignedUser"
            key="assignedUser"
            render={(assignedUser) => assignedUser?.fullName || 'Unassigned'}
          />
        </Table>
      </PageContent>
    </>
  );
};
