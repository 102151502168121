import { gql, useQuery } from '@apollo/client';

export interface SuperAdmin {
  id: string;
  username: string;
  fullName?: string;
}

export interface SuperAdminsResponse {
  superAdmins: SuperAdmin[];
}

export const SUPER_ADMINS_NAME = 'superAdmins';
export const SUPER_ADMINS = gql`
    query ${SUPER_ADMINS_NAME} {
    ${SUPER_ADMINS_NAME} {
      id
      username
      fullName
  }
}
`;

// Might need to add partners here someday but not today snitch
export function useSuperAdminsQuery() {
  const { data, error, loading, refetch } = useQuery<SuperAdminsResponse>(SUPER_ADMINS, {});
  return {
    superAdmins: data?.superAdmins || [],
    error,
    loading,
    refetch,
  };
}
