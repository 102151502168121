import React, { useCallback, useImperativeHandle } from 'react';

import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { validateEmail } from '../../../../api/auth.api';
import { NewOrganizationRequest } from '../../../../graphql/mutations/newOrganization';
import { FormComponentProps, FormRef } from '../../../../models/form';
import { Spacer } from '../../Spacer';
import { LabelBodyB } from '../../Text/Text.styled';
import TextField from '../../TextField';
import FormSelectField, { SelectOption } from '../../FormSelectField/FormSelectField';
import { HasPermission } from '../../Security';
import { RoleEnum } from '../../../../models/session';
import { useParams } from 'react-router-dom';
import { FormattedPlace, PlaceAutocomplete } from '../../PlaceAutoComplete';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().default('').required(),
  website: Yup.string().default('').required(),
  address: Yup.string().default('').required(),
  city: Yup.string().default('').required(),
  state: Yup.string().default('').required(),
  zipCode: Yup.string().default('').required(),
  fullName: Yup.string().default('').required(),
  email: Yup.string()
    .default('')
    .required()
    .test('is-not-new-user', 'Email already exists', async (value) => {
      if (value) {
        const existsUser = await validateEmail({ email: value || '' });
        return !existsUser;
      }

      return false;
    }),
  phoneNumber: Yup.string().default('').required(),
  partnerId: Yup.string().notRequired(),
});

const HorizontalContainer = styled.div`
  display: flex;
`;
export interface NewOrganizationFormValue extends NewOrganizationRequest {}
export interface NewOrganizationFormProps extends FormComponentProps<NewOrganizationFormValue> {
  partnerOptions: SelectOption[];
}

const NewOrganizationForm = React.forwardRef<FormRef<NewOrganizationFormValue>, NewOrganizationFormProps>(
  (props: NewOrganizationFormProps, ref) => {
    const { handleSubmit, control, errors, formState, setValue, trigger } = useForm<NewOrganizationFormValue>({
      resolver: yupResolver(ValidationSchema),
      mode: 'onTouched',
    });

    const { partnerId: selectedPartnerId } = useParams<{ partnerId: string }>();

    const { partnerOptions } = props;

    const onSubmit = (data: NewOrganizationFormValue) => {
      props.onCompleted(data);
    };
    const submitHandler = handleSubmit(onSubmit);
    useImperativeHandle(
      ref,
      () => ({
        formState,
        submit: submitHandler,
      }),
      [formState, submitHandler]
    );

    const setSelectedPlace = useCallback(
      (place: FormattedPlace | null) => {
        if (!place) return;

        const { name, address, city, state, website, zip } = place;

        setValue('name', name);
        setValue('website', website);
        setValue('address', address);
        setValue('city', city);
        setValue('state', state);
        setValue('zipCode', zip);

        trigger(['name', 'website', 'address', 'city', 'state', 'zipCode']);
      },
      [setValue, trigger]
    );

    return (
      <>
        <PlaceAutocomplete onPlaceSelect={setSelectedPlace} label="Find on Google" />
        <Spacer type="stack" size="m" />
        <form onSubmit={submitHandler}>
          <TextField
            type="text"
            name="name"
            label="NAME"
            placeholder="Location Name"
            control={control}
            errors={errors}
          />
          <Spacer type="stack" size="l" />
          <TextField
            type="text"
            name="website"
            label="WEBSITE"
            placeholder="Location address"
            control={control}
            errors={errors}
          />
          <Spacer type="stack" size="l" />
          <TextField
            type="text"
            name="address"
            label="ADDRESS"
            placeholder="Legal company address"
            control={control}
            errors={errors}
          />
          <Spacer type="stack" size="l" />
          <TextField type="text" name="city" label="CITY" placeholder="City" control={control} errors={errors} />
          <Spacer type="stack" size="l" />
          <HorizontalContainer>
            <TextField type="text" name="state" label="STATE" placeholder="AL" control={control} errors={errors} />
            <Spacer type="inline" size={'xs'} />
            <TextField
              type="text"
              name="zipCode"
              label="ZIP"
              placeholder="00000-0000"
              control={control}
              errors={errors}
            />
          </HorizontalContainer>
          <Spacer type="stack" size={'l'} />
          <LabelBodyB>Point of Contact for Organization</LabelBodyB>
          <Spacer type="stack" size={'m'} />
          <TextField
            type="text"
            name="fullName"
            label="FULL NAME"
            placeholder="John Doe"
            control={control}
            errors={errors}
          />
          <Spacer type="stack" size={'m'} />
          <TextField
            type="text"
            name="email"
            label="EMAIL"
            placeholder="johndoe@mail.com"
            control={control}
            errors={errors}
          />
          <Spacer type="stack" size={'m'} />
          <TextField
            type="text"
            name="phoneNumber"
            label="PHONE"
            placeholder="(555) 555-1234"
            control={control}
            errors={errors}
          />
          <Spacer type="stack" size="m" />
          <HasPermission allowedRoles={[RoleEnum.SuperAdmin]}>
            <FormSelectField
              name="partnerId"
              label="PARTNER"
              defaultValue={selectedPartnerId ?? undefined}
              placeholder="Select the partner for the organization"
              options={partnerOptions}
              control={control}
              errors={errors}
            />
            <Spacer type="stack" size="m" />
          </HasPermission>
        </form>
      </>
    );
  }
);

export default NewOrganizationForm;
