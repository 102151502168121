import React, { useEffect, useRef, useState } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { LabelBoldStyled } from '../TextInput/TextInput.styled';

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: FormattedPlace | null) => void;
  label: string;
}

export type FormattedPlace = {
  name?: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  website?: string;
};

export const PlaceAutocomplete = ({ onPlaceSelect, label }: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<any>(null);
  const places = useMapsLibrary('places');

  const extractAddressDetails = (place: google.maps.places.PlaceResult) => {
    const address = place.address_components?.find(
      (component) => component.types.includes('route') || component.types.includes('street_address')
    );

    const streetNumber = place.address_components?.find((component) => component.types.includes('street_number'));

    const city = place.address_components?.find(
      (component) => component.types.includes('locality') // 'locality' is the city
    );

    const state = place.address_components?.find(
      (component) => component.types.includes('administrative_area_level_1') // 'administrative_area_level_1' is the state
    );

    const zip = place.address_components?.find(
      (component) => component.types.includes('postal_code') // 'postal_code' is the zip code
    );

    const country = place.address_components?.find(
      (component) => component.types.includes('country') // 'country' is the country name
    );

    const streetAddress = streetNumber && address ? `${streetNumber.long_name} ${address.long_name}` : '';

    return {
      address: streetAddress, // Full street address
      city: city ? city.long_name : '', // City name
      state: state ? state.long_name : '', // State name
      zip: zip ? zip.long_name : '', // Zip code
      country: country ? country.short_name : '', // Country name
    };
  };

  useEffect(() => {
    if (!places || !inputRef.current) return;

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      const place = placeAutocomplete.getPlace();

      const { address, city, state, zip, country } = extractAddressDetails(place);

      const website = place.website;
      let websiteFormatted: string | undefined;
      if (website) {
        const newUrl = new URL(website);
        newUrl.search = ''; // Clear the search parameters
        websiteFormatted = newUrl.toString();
      }

      const placeObject = {
        name: place.name,
        address,
        city,
        state,
        zip,
        country,
        website: websiteFormatted,
      };

      onPlaceSelect(placeObject);
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'start',
        paddingTop: '18px',
      }}
    >
      {label && <LabelBoldStyled>{label?.toUpperCase()}</LabelBoldStyled>}
      <input
        style={{
          width: '100%',
          padding: '8px 12px',
          fontSize: '14px',
          lineHeight: '1.5715',
          color: 'rgba(0, 0, 0, 0.85)',
          backgroundColor: '#fff',
          border: '1px solid #d9d9d9',
          borderRadius: '6px',
          transition: 'all 0.2s',
          outline: 'none',
          boxShadow: 'none',
        }}
        placeholder={'Start typing to search for a location'}
        ref={inputRef}
      />
    </div>
  );
};
