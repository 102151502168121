import { useHistory } from 'react-router-dom';
import { useSession } from '../context/AppStateProvider';
import { useCallback } from 'react';
import { mockRequest } from '../api/auth.api';
import { getDefaultHome, handleLoginResponse } from '../pages/Login';

export const useMockUser = () => {
  const { loadSession } = useSession();
  const history = useHistory();

  const mockUserId = useCallback(
    async (userId: string) => {
      const response = await mockRequest(userId);
      const tokenInfo = await handleLoginResponse(response, loadSession);
      const path = getDefaultHome(tokenInfo.roles);
      history.push(path);
    },
    [loadSession, history]
  );

  return mockUserId;
};
