import React, { useState } from 'react';

import { Checkbox, Dropdown, Space } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group';

import { DownOutlined } from '@ant-design/icons';

import styled from 'styled-components';

import { LabelB, LabelBold } from '../Text/Text.styled';
import { SearchInput } from '../TextInput/SearchInput.styled';

const CheckboxGroup = Checkbox.Group;

const CheckBoxOptionsWrapper = styled(CheckboxGroup)`
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow: auto;
  .ant-checkbox-wrapper {
    padding: 8px;
  }
`;
const CheckBoxWrapper = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  box-shadow: ${({ theme }) => theme.shadows.l};
  background: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;
const ActionWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.dimGray.plus3};
  border: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  &:hover {
    cursor: pointer;
  }
`;

const Divider = styled(LabelB)`
  color: ${(props) => props.theme.colors.dimGray.plus1};
  padding: 8px;
`;

interface FilterContentProps {
  searchText?: string;
  options: CheckboxOptionType[];
  dividerLabel?: string;
  checkAllLabel?: string;
  showSearch?: boolean;
  searchFunction?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSelect: (select: any[]) => void;
  selected: any[];
}

export const FilterContent: React.FC<FilterContentProps> = ({
  searchText,
  options,
  dividerLabel,
  checkAllLabel,
  showSearch,
  searchFunction,
  setSelect,
  selected,
}) => {
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list: CheckboxValueType[]) => {
    setSelect(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setSelect(e.target.checked ? options.map((option) => option.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <CheckBoxWrapper>
      {showSearch && <SearchInput placeholder={searchText ?? 'Search'} onChange={searchFunction} removeBorder />}
      {checkAllLabel && (
        <Checkbox
          style={{ padding: '8px' }}
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {checkAllLabel}
        </Checkbox>
      )}
      {dividerLabel && <Divider>{dividerLabel}</Divider>}
      <CheckBoxOptionsWrapper options={options} value={selected} onChange={onChange} />
    </CheckBoxWrapper>
  );
};

interface FilterWithCheckBoxProps extends FilterContentProps {
  title: string;
  disabled?: boolean;
  label?: string;
}

const FilterWithCheckBox: React.FC<FilterWithCheckBoxProps> = ({
  title,
  options,
  label,
  showSearch,
  disabled,
  ...props
}) => {
  const checkbox = <FilterContent options={options} showSearch={showSearch} {...props} />;
  return (
    <div>
      {label && <LabelBold>{label}</LabelBold>}
      <Dropdown overlay={checkbox}>
        <ActionWrapper>
          <Space>
            {title}
            {!disabled && <DownOutlined />}
          </Space>
        </ActionWrapper>
      </Dropdown>
    </div>
  );
};

export default FilterWithCheckBox;
