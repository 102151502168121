import { gql } from '@apollo/client';

export interface SetMarketplaceLocationRequest {
  marketplaceToken: string;
  marketplaceLocation: string | null;
}
export interface SetMarketplaceLocationResponse {
  setMarketplaceLocationOverride: boolean;
}

export const SET_MARKETPLACE_LOCATION = gql`
  mutation setMarketplaceLocationOverride($marketplaceToken: String!, $marketplaceLocation: String) {
    setMarketplaceLocationOverride(marketplaceToken: $marketplaceToken, marketplaceLocation: $marketplaceLocation)
  }
`;
