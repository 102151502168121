import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { NavigationTabItemWrapper } from './NavigationTabItem.styled';
import { FaCar, FaCircleQuestion, FaHeart, FaRegHandshake, FaRocket } from 'react-icons/fa6';

const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const iconLookup = {
  inbox: 'module-inbox',
  dashboard: 'module-dashboard',
  people: 'module-people',
  organization: 'module-organizations',
  calendar: 'icon-small-misc-calendar',
  menuOptions: 'icon-small-misc-menu-option',
  inventory: <FaCar />,
  connect: 'connect',
  queue: 'icon-small-misc-information',
  handshake: <FaRegHandshake />,
  rocket: <FaRocket />,
  help: <FaCircleQuestion />,
  health: <FaHeart />,
};

export interface NavigationTabItemProps {
  icon: keyof typeof iconLookup;
  tooltipText?: string;
  active?: boolean;
  alert?: boolean;
}

export const NavigationTabItem: React.FC<NavigationTabItemProps> = ({ icon, active, alert, tooltipText }) => {
  let IconComponenet;
  const value = iconLookup[icon];
  if (typeof value === 'string') {
    IconComponenet = <i className={`icon ${iconLookup[icon]}`} />;
  } else {
    IconComponenet = iconLookup[icon];
  }

  return (
    <TabWrapper>
      <Tooltip placement="right" title={tooltipText}>
        <NavigationTabItemWrapper active={active}>{IconComponenet}</NavigationTabItemWrapper>
      </Tooltip>
      {alert && (
        <Tooltip placement="right" color="red" title={'Connect your accounts first.'}>
          <div>alert</div>
        </Tooltip>
      )}
    </TabWrapper>
  );
};

NavigationTabItem.defaultProps = {
  active: false,
};
