import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Button, Card, Space } from 'antd';
import Link from 'antd/lib/typography/Link';
import { FacebookOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FaFacebookMessenger } from 'react-icons/fa6';

export enum MarketplaceErrorFlag {
  NotLoggedIn = 'notLoggedIn',
  OnBusinessProfile = 'onBusinessProfile',
  IncorrectCredentials = 'incorrectCredentials',
  PinRequired = 'pinRequired',
}

export const FACEBOOK_LOGIN_PAGE = 'https://www.facebook.com/';
export const MARKETPLACE_MESSAGES_PAGE = 'https://www.facebook.com/messages/t';

const { Title, Text } = Typography;

const MainWrapper = styled.section`
  height: 100%;
  width: 100%;
  padding-top: 31.77vh;
  display: flex;
  justify-content: center;
`;

const MainContainer = styled.div`
  width: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const REASON_LIST = [
  <Text type="secondary">
    Dealerships can <Text strong>convert up to 25-30% of leads generated from Facebook Marketplace into sales</Text> ,
    higher than many other platforms.
  </Text>,
  <Text type="secondary">
    Dealerships on Facebook Marketplace have <Text strong>reported a 15-20% increase in monthly sales</Text>, as the
    platform allows for direct engagement with interested buyers.
  </Text>,
  <Text type="secondary">
    Facebook Marketplace are<Text strong> 80% more likely to respond and set up appointments</Text> compared to
    traditional online listings.
  </Text>,
];

const ExtensionErrorRedirect: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const queryObject = Object.fromEntries(query.entries());
  const queryError = queryObject.error;

  const randomReason = REASON_LIST[Math.floor(Math.random() * REASON_LIST.length)];

  let errorBanner;
  if (queryError === MarketplaceErrorFlag.NotLoggedIn) {
    errorBanner = <FacebookLoginPrompt reason={randomReason} />;
  }
  if (queryError === MarketplaceErrorFlag.OnBusinessProfile) {
    errorBanner = <FacebookBusinessPrompt reason={randomReason} />;
  }

  if (queryError === MarketplaceErrorFlag.IncorrectCredentials) {
    errorBanner = <IncorrectLoginPrompt reason={randomReason} />;
  }

  if (queryError === MarketplaceErrorFlag.PinRequired) {
    errorBanner = <PinRequiredPrompt />;
  }

  return (
    <MainWrapper>
      <MainContainer>
        <Card
          style={{
            maxWidth: '600px',
            textAlign: 'center',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          {errorBanner}
        </Card>
      </MainContainer>
    </MainWrapper>
  );
};

export const FacebookLoginPrompt: React.FC<{ reason: ReactNode }> = ({ reason }) => {
  return (
    <Space direction="vertical" size="middle">
      <FacebookOutlined style={{ fontSize: '48px', color: '#4267B2' }} />
      <Title level={3}>Log Back Into Facebook</Title>
      <Text type="secondary">
        To continue selling cars and reach more potential buyers, you need to log back into your Facebook account.{' '}
        {reason}
      </Text>
      <Link href={FACEBOOK_LOGIN_PAGE} target="_blank">
        <Button type="primary" icon={<FacebookOutlined />} size="large" block onClick={() => {}}>
          Log in to Facebook
        </Button>
      </Link>
    </Space>
  );
};

export const FacebookBusinessPrompt: React.FC<{ reason: ReactNode }> = ({ reason }) => {
  return (
    <Space direction="vertical" size="middle">
      <FacebookOutlined style={{ fontSize: '48px', color: '#4267B2' }} />
      <Title level={3}>Switch Back to Personal Profile</Title>
      <Text type="secondary">
        To continue selling cars and reach more potential buyers, you need to switch back to your personal profile and
        not your Business Page. {reason}
      </Text>
      <Link href={FACEBOOK_LOGIN_PAGE} target="_blank">
        <Button type="primary" icon={<FacebookOutlined />} size="large" block>
          Switch to Personal Profile
        </Button>
      </Link>
    </Space>
  );
};

export const IncorrectLoginPrompt: React.FC<{ reason: ReactNode }> = ({ reason }) => {
  return (
    <Space direction="vertical" size="middle">
      <FacebookOutlined style={{ fontSize: '48px', color: '#4267B2' }} />
      <Title level={3}>We were unable to log you back into Facebook.</Title>
      <Text type="secondary">
        Make sure your login credentials are entered into the extension correctly and be sure to log back into Facebook.{' '}
        {reason} If you are still have trouble please reach out to LocalShift Support.
      </Text>
      <Link href={FACEBOOK_LOGIN_PAGE} target="_blank">
        <Button type="primary" icon={<FacebookOutlined />} size="large" block onClick={() => {}}>
          Log in to Facebook
        </Button>
      </Link>
      <Link href="mailto:support@localshift.io">
        <Button type="primary" size="large" block>
          Contact Support
        </Button>
      </Link>
    </Space>
  );
};

export const PinRequiredPrompt: React.FC = () => {
  return (
    <Space direction="vertical" size="middle">
      <FaFacebookMessenger style={{ fontSize: '48px', color: '#4267B2' }} />
      <Title level={3}>We are unable to access your Marketplace messages.</Title>
      <Text type="secondary">
        Make sure to enter your PIN number into your Facebook Messenger account. If you are still have trouble please
        reach out to LocalShift Support.
      </Text>
      <Link href={MARKETPLACE_MESSAGES_PAGE} target="_blank">
        <Button type="primary" icon={<FaFacebookMessenger />} size="large" block onClick={() => {}}>
          Open Facebook Messenger
        </Button>
      </Link>
      <Link href="mailto:support@localshift.io">
        <Button type="primary" size="large" block>
          Contact Support
        </Button>
      </Link>
    </Space>
  );
};

export default ExtensionErrorRedirect;
